import deepOrange from '@material-ui/core/colors/deepOrange';
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { themeOptions } from './config';

// Create a theme instance.
const theme = responsiveFontSizes(
  createMuiTheme({
    palette: {
      primary: themeOptions.palette?.primary || {
        light: '#ed1c24',
        main: '#ed1c24',
        dark: '#970000',
        contrastText: '#ffffff',
      },
      secondary: themeOptions.palette?.secondary || deepOrange,
      appBar: themeOptions.palette?.appBar || {
        background: '#F7D417',
        color: {
          contrastText: '#000000',
          logo: '#000000',
        },
      },
      mainNavigation: themeOptions.palette?.mainNavigation || {
        background: '#B3B3B3',
        backgroundActive: '#000000',
      },
      mainNavigationContent: themeOptions.palette?.mainNavigationContent || {
        background: '#F7D417',
      },
      background: themeOptions.palette?.background || {
        default: '#FFFFFF',
      },
      appFooter: themeOptions.palette?.appFooter || {
        background: '#ed1c24',
        color: '#FFFFFF',
      },
    },
    typography: {
      // fontSize: 12,
      h1: {
        fontSize: '1.8rem',
        fontWeight: 900,
        // margin: '16px 0 24px 0',
      },
      h2: {
        fontSize: '2.4rem',
        fontWeight: 400,
        // margin: '16px 0 24px 0',
      },
      h3: {
        fontSize: '2rem',
      },
      h4: {
        fontSize: '1.8rem',
      },
      h5: {
        fontSize: '1.6rem',
      },
      h6: {
        fontSize: '1.4rem',
      },
      subtitle1: {
        fontSize: '1.1rem',
      },
      subtitle2: {
        fontSize: '1rem',
      },
    },
    overrides: {
      MuiMenuItem: {
        root: {
          '@media(max-width:959px)': {
            whiteSpace: 'initial',
          },
        },
      },
      // SxComProductImage: {
      //   'size-big-landscape': {
      //     '@media(min-width:960px)': {
      //       height: 600,
      //       width: 600,
      //     },
      //   },
      // },
    },
  }),
);

export default theme;
