import { Drawer, IconButton, makeStyles } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/CloseTwoTone';
import React, { useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { navigationDrawerOpenState } from '../../state/atoms';
import useRouteUrlFactory from '../../hooks/useRouteUrlFactory';
import useTranslateWithFallback from '../../hooks/useTranslateWithFallback';
import { isAuthenticatedState } from '../../api/appState';
import type { ProductTaxonomyType } from '../../@sprinx/knihovka-types';
import { productTaxonomyTypesQuery } from '../../api/products/taxonomyTypes';
import NavigationLinks from '../NavigationLinks';

const DRAWER_WIDTH = 280;

const useStyles = makeStyles((theme) => ({
  root: {
    width: DRAWER_WIDTH,
    minWidth: DRAWER_WIDTH,
    display: 'flex',
    flexDirection: 'column',
    border: 'none',
    boxShadow: 'none',
    padding: theme.spacing(0, 0),
    overflowY: 'unset',
    flexGrow: 1,
    gap: theme.spacing(4),
  },

  toolbar: {},

  title: {
    fontSize: '1.6rem',
    fontWeight: 600,
    padding: theme.spacing(0, 1.5),
    margin: 0,
  },

  links: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },

  link: {
    display: 'flex',
    gap: theme.spacing(1),
    padding: theme.spacing(0, 1.5),
    fontSize: '1.2rem',
    color: '#000',
    textDecoration: 'none',

    '&:hover': {
      textDecoration: 'none',
      color: '#fff',
      backgroundColor: '#f26065',
    },
  },

  navLinksWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0, 1.5),
    gap: theme.spacing(2),
  },
}));

export default function NavigationDrawer(): JSX.Element {
  const routeUrlFactory = useRouteUrlFactory();
  const t = useTranslateWithFallback();
  const taxonomies = useRecoilValue(productTaxonomyTypesQuery);
  const [open, setOpen] = useRecoilState(navigationDrawerOpenState);
  const handleClose = () => setOpen(false);
  const classes = useStyles();
  const isAuthenticated = useRecoilValue(isAuthenticatedState);

  const taxonomiesList = useMemo<ProductTaxonomyType[]>(() => {
    if (isAuthenticated != true) return [];
    return taxonomies
      .filter((x) => x.extra.depth === 2)
      .map((x) => ({ ...x, code: x.code.replace('/catalogue/', '') }));
  }, [taxonomies, isAuthenticated]);

  return (
    <Drawer open={open} onClose={handleClose}>
      <div className={classes.root}>
        <div className={classes.toolbar}>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        {taxonomiesList.length > 0 && (
          <div className={classes.links}>
            <h2 className={classes.title}>{t('mainNavigation/catalogueMenu')}</h2>
            {taxonomiesList.map((node) => {
              return (
                <RouterLink
                  className={classes.link}
                  key={node.code}
                  to={routeUrlFactory('categories', { params: { categories: node.code } })}
                >
                  <div>{t(node.label)}</div>
                </RouterLink>
              );
            })}
          </div>
        )}
        <div className={classes.links}>
          <h2 className={classes.title}>{t('mainNavigation/menu')}</h2>
          <NavigationLinks className={classes.navLinksWrapper} isAsideLayout />
        </div>
      </div>
    </Drawer>
  );
}
